import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/webcamsession': {
		...talentRouteDefaultConfig,
		as: 'webcamsession',
		needsOnboarding: false,
		hasPageTracking: true,
		hasHeader: false,
		hasAsideNavigation: false,
		module: () => import('./'),
	},
};
