import moment from 'moment-timezone';

export type DeviceType = 'mobile' | 'desktop';
type someAPIUser = APIuser | APItalent | APIcompany;

export class BaseUser {
	user?: someAPIUser;

	crossTabsCommunication = new BroadcastChannel('apiUserObject');

	constructor() {
		this.crossTabsCommunication.onmessage = ({
			data,
		}: MessageEvent<someAPIUser>) => {
			this.updateUser(data, false);
		};
	}

	browserNotifications: NotificationPermission | null =
		'Notification' in window ? Notification.permission : null;

	systemzone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

	updateUser(
		propertiesToUpdate: someAPIUser | Partial<someAPIUser>,
		postToTabs = true,
	) {
		this.user = {
			...this.user,
			...propertiesToUpdate,
		};
		if (propertiesToUpdate.timezone) {
			moment.tz.setDefault(propertiesToUpdate.timezone);
		}
		if (postToTabs) this.crossTabsCommunication.postMessage(this.user);
	}

	get hasSession(): boolean {
		return !!this.user?.id;
	}

	get preferredLanguage(): string {
		return (
			this.user?.lang ||
			(window.navigator.language
				? window.navigator.language.split('-')[0]
				: 'en')
		);
	}

	get emailVerified(): boolean {
		return !!this.user?.emailverified;
	}

	can(feature: string = ''): boolean {
		const { features = [] } = this.user || {};
		return features.includes(feature);
	}

	get ymmdTeam(): boolean {
		return (
			!!this.user?.email &&
			/@(external\.|icon\.)?yoummday\.com$/u.test(this.user.email)
		);
	}

	get ymdMail(): string | undefined {
		return this.user?.ymdmail;
	}

	get device(): {
		type: DeviceType;
		hasTouchScreen: boolean;
	} {
		const type: DeviceType = window.innerWidth <= 768 ? 'mobile' : 'desktop';
		const hasTouchScreen =
			'ontouchstart' in document.documentElement &&
			navigator.maxTouchPoints > 1;
		return { type, hasTouchScreen };
	}
}
