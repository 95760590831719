import { talentRouteDefaultConfig, type TalentRoute } from '../router';

export default <Record<string, TalentRoute>>{
	'/contract/:contractId/:userId/:dpaStatus': {
		...talentRouteDefaultConfig,
		as: 'contract',
		hasRocketchat: true,
		module: () => import('./contract.js'),
	},
	'/contract/:contractId/:userId': {
		...talentRouteDefaultConfig,
		as: 'contract',
		hasRocketchat: true,
		module: () => import('./contract.js'),
	},
	'/contract/:contractId': {
		...talentRouteDefaultConfig,
		as: 'contract',
		hasRocketchat: true,
		module: () => import('./contract.js'),
	},
	'/contract': {
		...talentRouteDefaultConfig,
		as: 'contract',
		hasRocketchat: true,
		module: () => import('./contract.js'),
	},
	'/join': {
		...talentRouteDefaultConfig,
		as: 'join',
		needsOnboarding: false,
		needsNoOnboarding: true,
		hasPageTracking: true,
		module: () => import('./join.js'),
	},
	'/join/:page/:topic': {
		...talentRouteDefaultConfig,
		as: 'join',
		needsOnboarding: false,
		needsNoOnboarding: true,
		hasPageTracking: true,
		module: () => import('./join.js'),
	},
	'/join/:page': {
		...talentRouteDefaultConfig,
		as: 'join',
		needsOnboarding: false,
		needsNoOnboarding: true,
		hasPageTracking: true,
		module: () => import('./join.js'),
	},
	'/join/:page/:companyId/:moduleId': {
		...talentRouteDefaultConfig,
		as: 'join',
		needsOnboarding: false,
		needsNoOnboarding: true,
		hasPageTracking: true,
		module: () => import('./join.js'),
	},
	'/income': {
		...talentRouteDefaultConfig,
		as: 'income',
		hasRocketchat: true,
		module: () => import('./income.js'),
	},
	'/dialerwork/:contractId': {
		...talentRouteDefaultConfig,
		as: 'dialerwork',
		hasRocketchat: true,
		module: () => import('./dialerwork.js'),
	},
	'/outboundwork/:contractId': {
		...talentRouteDefaultConfig,
		as: 'outboundwork',
		hasRocketchat: true,
		module: () => import('./outboundwork.js'),
	},
	'/outboundwork': {
		...talentRouteDefaultConfig,
		as: 'outboundwork',
		hasRocketchat: true,
		module: () => import('./outboundwork.js'),
	},
	'/calls': {
		...talentRouteDefaultConfig,
		as: 'calls',
		hasRocketchat: true,
		module: () => import('./calls.js'),
	},
	'/schedule': {
		...talentRouteDefaultConfig,
		as: 'schedule',
		hasRocketchat: true,
		module: () => import('./schedule.js'),
	},
	'/schedule/:contractId': {
		...talentRouteDefaultConfig,
		as: 'schedule',
		hasRocketchat: true,
		module: () => import('./schedule.js'),
	},
	'/language-evaluation/results/:sessionId': {
		...talentRouteDefaultConfig,
		as: 'language-evaluation',
		hasHeader: false,
		hasAsideNavigation: false,
		needsOnboarding: false,
		module: () => import('./language-evaluation.js'),
	},
	'/language-evaluation': {
		...talentRouteDefaultConfig,
		as: 'language-evaluation',
		hasHeader: false,
		hasAsideNavigation: false,
		needsOnboarding: false,
		module: () => import('./language-evaluation.js'),
	},
};
