/* eslint-disable no-undefined */
// https://openapi-ts.dev/openapi-fetch/middleware-auth
import type { Middleware } from 'openapi-fetch';
import { html, TemplateResult } from 'lit';

export const addApiErrorMessages = (
	errorTexts = { v2: { response: {} } },
	onError: (
		errorMsg: string | TemplateResult,
		error: string,
		path: string,
	) => void = () => {},
): Middleware => ({
	onResponse: async ({ response, schemaPath }) => {
		if (!response.headers.get('content-type')?.includes('application/json')) {
			return undefined;
		}

		const { status } = response;
		const { error } = await response.clone().json(); // https://openapi-ts.dev/openapi-fetch/middleware-auth#handling-statefulness

		if (!error || ![500, 400, 404, 409, 422].includes(status)) {
			return undefined;
		}

		const path = schemaPath.replaceAll('/', '');
		let errorCode, errorMsg;
		if (typeof error === 'string') {
			errorCode = error;
			// v1 error message not specific to endpoint
			errorMsg = errorTexts[errorCode] || '';
			// v1 error message specific to endpoint
			errorMsg = errorTexts[path]?.[errorCode] || errorMsg;
		} else if (error.errors?.length) {
			errorCode = error.code;
			// v2 error message not specific to endpoint
			errorMsg = errorTexts.v2?.[errorCode] || errorMsg;
			// v2 error messages from list of errors
			errorMsg = html`
				${errorMsg}
				<br />
				<ul>
					${error.errors.map(
						({ name }: { name: string }) => html`
							<li>
								${errorTexts.v2.response[name.replace(/\[\d+\]\./gu, '')]}
							</li>
						`,
					)}
				</ul>
			`;
		}

		onError?.(errorMsg, errorCode, path);

		return undefined;
	},
});
