import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/welcome': {
		...talentRouteDefaultConfig,
		as: 'welcome',
		needsOnboarding: false,
		needsNoOnboarding: true,
		isAllowedWithoutVerifiedEmail: true,
		hasPageTracking: true,
		module: () => import('./'),
	},
};
