import { talentRouteDefaultConfig, type TalentRoute } from '../../router';

export default <Record<string, TalentRoute>>{
	'/onboarding/:step': {
		...talentRouteDefaultConfig,
		as: 'onboarding',
		needsOnboarding: false,
		needsNoOnboarding: true,
		hasPageTracking: true,
		module: () => import('./'),
	},
	'/onboarding': {
		...talentRouteDefaultConfig,
		as: 'onboarding',
		needsOnboarding: false,
		needsNoOnboarding: true,
		hasPageTracking: true,
		module: () => import('./'),
	},
};
